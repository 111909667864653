<template>
  <div class="main">
    <img src="@/assets/imges/404.svg" />
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.main {
  width: 1024px;
  height: 320px;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
